<template>
  <div class="Subscription">
    <div class="card mb-4" v-if="legacyPlan">
      <div class="card-body" style="background: #E3EAEC">
        <h3 style="margin-top: 0">We've updated our plans & pricing</h3>
        <p>Your current plan is now classified as a <strong>Legacy plan</strong>, which is not included in the table below and comes with different features and limitations. To fully benefit from Pulsetic’s capabilities, we recommend selecting one of the paid plans listed below. If you have any questions, please contact our support team; we are happy to assist you.</p>
        <base-button class="mt-2" @click="openBeacon">Contact Support</base-button>
      </div>
    </div>
    <div class="card">
      <div class="card-body">
        <subscription v-if="user.subscription_status !== 'lifetime'" />
        <div v-else>
          You have life time subscription. Your current monitors limit is: {{user.limits ? user.limits.monitors : user.monitors_limit}}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Subscription from '@/components/Account/Subscription/Subscription.vue'

export default {
  components: {
    Subscription
  },

  metaInfo: {
    title: 'Subscription'
  },

  methods: {
    openBeacon () {
      window.Beacon('open')
      let beaconPopup = document.getElementById('beacon-container')
      setTimeout(() => {
        let beaconInner = null
        if (beaconPopup) {
          beaconPopup = document.getElementById('beacon-container')
          beaconInner = beaconPopup.getElementsByClassName('Beacon')[0]
        }
        if (!beaconPopup || !beaconInner?.innerHTML) {
          window.Beacon('init', 'f9e84fd2-f54f-4b3f-a38b-a540e8793bed')
          window.Beacon('open')
        }
      }, 100)
    }
  },

  computed: {
    ...mapState('authentication', [
      'user'
    ]),

    legacyPlan () {
      const legacyPlans = ['Start', 'Pro', 'Business']
      return legacyPlans.includes(this.user.subscription_plan.name)
    }
  }
}
</script>

<style lang="scss" scoped>
  .Subscription {

  }
</style>
